.no-gutter.row,
.no-gutter.container,
.no-gutter.container-fluid {
  margin-left: 0;
  margin-right: 0;
}

.no-gutter>[class^="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.row {
  padding: 0px;
  margin: 0px;
}

.head-text {
  text-align: center;
  padding: 0px 20px;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  background-color: #cbf1ff;
  color: black;
  /* background-color: #eee; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid black;
}

.head-text-fees {
  text-align: center;
  padding: 0px 20px;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  background-color: #cbf1ff;
  color: black;
  /* background-color: #eee; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid black;
  /* border-bottom: none; */
}


.navigation-button {
  margin-right: 10px;
}

.simple-border {
  border: 1px solid black;
}

.simple-border-2 {
  border: 2px solid black;
}

.simple-border-left {
  border-left: 1px solid black;
}

.simple-border-right {
  border-right: 1px solid black;
}

.simple-border-top {
  border-top: 1px solid black;
}

.simple-border-bottom {
  border-bottom: 1px solid black;
}



.svg_icons {
  transform: scale(1.8);
}

.chart-container {
  height: 550px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  cursor: pointer !important;
}

.inline-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}


.btn-primary {
  background-color: #336699;
}

.btn-success-transaction {
  background-color: #00b7fd;
  border: #00b7fd;
}

.success-text {
  color: #00b7fd;
}

.failure-text {
  color: red;
}


.top-nav-links div {
  background-color: #eee;
  color: #111 !important;
  font-size: 20px;
  font-weight: normal;
  padding: 5px 20px 5px 20px;
  border-radius: 4px;
}


.sidebar {
  background-color: #f1f7fd;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100vh - 140px);
}

.sidebar-links-button {
  background-color: #d4e3fc;
  margin: 20px 10px 0px 10px;
  width: 150px;
  border: none;
  padding: 5px;
  cursor: pointer;
}

.regitration-container {
  background-color: rgb(230, 237, 184);
  height: calc(100vh - 9rem);
  display: flex;
  justify-content: center;
  align-items: center;
}

.generic-form-container {
  background-color: rgb(255, 250, 250);
  /* height: calc(100vh - 9rem); */
  display: flex;
  justify-content: center;
  align-items: center;
}

.generic-form-container .row {
  margin-bottom: 20px;
}

.avatar {
  vertical-align: middle;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 20px;
}

.application-type-container {

  border-radius: 0.5rem;
  border: 5px solid #00b7fd;
  cursor: pointer;
  font-size: 28px;
  min-width: 300px;
  max-width: 400px;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-weight: 600;
  flex-direction: column;
  box-sizing: border-box;
}

.application-type-container:hover {
  cursor: pointer;
  border: 5px solid #00b7fd;
}

.footer-container {
  padding-top: 0.5rem;
  /* padding-bottom: 0.5rem; */
  background-color: #00b7fd;
  color: white;
}

.footer-container .footer-feedback-button {
  background-color: white;
  color: black;
  border-radius: 0.25rem;
  padding: 5px;
  margin-left: 10px;
  border: 0px;
  outline: 0px;
  cursor: pointer;
}

.footer-container .footer-link-list {
  list-style-type: none;
  margin-left: -2rem;
}

.FTC_app_container {
  min-height: 8rem;
}

.CAC_app_container {
  min-height: 20rem;
  vertical-align: middle;
}


.top-nav-links a {
  font-weight: 800;
}

.bg-image {
  /* background: url('../images/Background FTC.png'); */
  /* background-size: cover; */
  background-color: #F2F8FA;
}

.RSPBprogressBar .RSPBstep {
  font-size: 13px !important;
  font-weight: 600;
}

.RSPBprogressBar .MuiSvgIcon-root {
  width: 14px;
  height: 14px;
}


.flex-spacing-10 * {
  margin-right: 10px;
}

.bottom-spacing-10 * {
  margin-bottom: 10px;
}

.flex-next-spacing-10>* {
  margin-bottom: 10px;
}

.btn-custom-success {
  border-radius: 5px;
  background-color: #cbf1ff;
  /* background-color: #00e 8bb; */
  color: black;
  font-weight: 600;
  font-size: 18px;
  padding: 5px 12px;
  border: 2px solid black;
}

.apply-border-button {
  border: 2px solid black;
}

.btn-custom-success:hover {
  background-color: white;
}


.btn-custom-previous {
  
  color: black;
  font-weight: 600;
  font-size: 18px;
  padding: 5px 12px;
  border: 2px solid black;
  border-radius: 5px;
}

.btn-custom-previous:hover {
  background-color: white;
}


.btn-custom-warning {
  background-color: #f0ef8a;
  color: black;
  font-weight: 600;
  font-size: 18px;
  padding: 5px 18px;
}

.btn-no-padding {
  padding: 0px 4px;
}

.btn-border{
  border: 1px solid black;
}

.btn-custom-plain-yellow-b {
  border-radius: 1px;
  border: 1px solid yellow;
  color: white;
  font-weight: 600;
  font-size: 18px;
  padding: 5px 18px;
}



.btn-custom-plain-green-b {
  border-radius: 1px;
  border: 1px solid green;
  color: black;
  font-weight: 600;
  font-size: 18px;
  padding: 5px 18px;
}

.custom-banner-bg {
  background-color: #00b7fd;
  padding: 40px 4%;
  color: white;
}


.btn-custom-plain-yellow-black-text {
  border-radius: 1px;
  border: 1px solid yellow;
  font-weight: 600;
  font-size: 18px;
  padding: 5px 18px;
  color: rgb(136 136 49);
  border: 4px solid rgb(136 136 49);
}

.license-cat-container {
  background: white;
  border: 2px solid #00b7fd;
  min-width: 225px;
  max-width: 225px;
  /* margin: 10px 30px; */
  margin: 10px 20px 15px 20px;
  cursor: pointer;
}

.license-cat-container:hover {
  color: black;
}

.license-cat-container-text {
  font-size: 24px;
  font-weight: 600;
}

.license-cat-wide-container{
  min-width: 500px;
}

.license-wide-cat-container-text{
  font-size: 20px;
}

.sub-cat-container {
  background: white;
  border: 2px solid #00b7fd;
  /* min-width: 150px; */
  max-width: 150px;
  min-width: 150px;
  /* margin: 10px 30px; */
  margin: 10px 30px 10px 0px;
  cursor: pointer;
  flex-wrap: wrap;
}

.sub-cat-container:hover {
  /* background-color: white; */
  color: black;
}

.location-cat-container {
  background: white;
  border: 2px solid #00b7fd;
  /* min-width: 150px; */
  max-width: 100px;
  min-width: 100px;
  /* margin: 10px 30px; */
  margin: 10px 10px 0px 0px;
  cursor: pointer;
  flex-wrap: wrap;
  font-size: 10px;
}

.sub-cat-container:hover {
  /* background-color: white; */
  color: black;
}

.rectangle-box-small-text {
  font-size: 14px;
  font-weight: 600;
}

.small-button-download-text {
  font-size: 10px;
}

.large-button {
  padding: 0px 12px;
  font-size: 20px;
}

.triangle-right {

  width: 10px;
  height: 10px;
  border-top: 18px solid transparent;
  border-left: 36px solid #555;
  border-bottom: 18px solid transparent;
}



.payment-type-cat-container {
  background: white;
  border: 2px solid green;
  min-width: 300px;
  max-width: 300px;
  cursor: pointer;
}

.payment-type-cat-container:hover {
  background-color: green;
  color: black !important;
}

.payment-type-cat-container-selected {
  background-color: green;
  color: whitesmoke !important;
  min-width: 300px;
  max-width: 300px;
  cursor: pointer;
}


.button-step-active {
  padding: 10px 20px;
  border: 1px solid black;
  border-radius: 5px;
  background: green;
  color: white;
  font-weight: bold;
}

.button-step {
  padding: 10px 20px;
  border: 1px solid black;
  border-radius: 5px;
  background: white;
  color: green;
  font-weight: bold;
}

.input-container {
  border: none;
  padding: 10px;
  margin-bottom: 10px;
  min-width: 400px;
  border-radius: 10px;
}

.input-container::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #222;
  font-weight: 600;
  opacity: 0.7;
  /* Firefox */
}

.nav-item {
  font-weight: 600;
  color: green;
  font-size: 20px;
}

.application-status-container {
  background-color: white;
  padding: 20px;

}

.form-control {
  border: 1px solid #263a4e !important;
}

.background-blue {
  background-color: #00b7fd;
  color: white;
}

.text-s-bold {
  font-weight: 600;
}

.text-bold {
  font-weight: 700;
}

.text-x-bold {
  font-weight: 800;
}

.little-big-text {
  font-size: 18px;
}

hr {
  margin: 0 !important;
  border-top: 1px solid rgba(0, 0, 0, 0.7);
}

.underline {
  text-decoration: underline;
}

.hover-disable {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.bg-primary {
  background-color: #00b7fd !important;
  /* color: #00b7fd; */
  color: white !important;
}

.navbar-light .navbar-brand {
  color: white;
}

.navbar-light .navbar-nav .nav-link {
  color: white;
}

.d-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flis-image {
  height: 65px;
}

.flis-second-image{
  height: 100px;
  /* max-width: 400px; */
}

.submitted-app-transaction-details {
  padding: 0 28%;
}

.nav-item{
  margin: 2px;
}

.nav-link{
  padding: 2px 10px;
  outline: none !important;
  font-weight: 600;
  /* color: #00b7fd !important; */
  color: black !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
  color: #b0becc;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
    border-top: 2px solid black;
}

.custom-card-header{
  padding: 0px;
  background-color: black; 
}

.custom-card-header button {
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.custom-card-header button:hover {
  color: white;
}
.custom-card-header button::after {
  font-family: 'Glyphicons Halflings';  /* essential for enabling glyphicon */
  font-size: 24px;
  content: '\2212';   /* adjust as needed, taken from bootstrap.css */
  float: right;        /* adjust as needed */
  color: grey;   
}

.custom-card-header button.collapsed::after {
  content: '\002B';
  font-size: 24px;
}
 


.custom-table-header{
  background-color: #00b7fd;
  color: white;
}

.mocked-link {
  color: #0000EE; /* Standard link color */
  text-decoration: underline; /* Underline text */
  cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
}

.mocked-link:hover,
.mocked-link:focus {
  color: #551A8B; /* Color changes on hover/focus */
}

.preserve-white-space{
  white-space: pre;
}

.height-100 {
  height: 100% !important;
}

.flex-full-height {
  flex: 1 1 100%; /* Take full width on its own row */
  height: 100%;   /* Full height of the container */
}


.flex-remaining-item {
  /* flex: 1 1 auto; */
  /* background-color: lightblue; */
  min-width: 100px; /* Minimum width for items */
  /* padding: 20px; */
  box-sizing: border-box;
}

.blue-gradient-bg {
  background: linear-gradient(145deg,#6ec1e4,#3a95e4);
  color: white;
}

@media screen and (max-width:600px) {
  .flis-image {
    height: 50px !important
  }

  .flis-second-image{
    height: 80px;
  }

  .RSPBprogressBar span {
    display: none;
    visibility: none;
  }

  .submitted-app-transaction-details {
    padding: 0 0%;
  }

}


@media screen and (max-width:800px) {

  .table-responsive {
    display: block;
    /* Changes the table layout to block on smaller screens */
  }

  .table-responsive tbody,
  .table-responsive tr,
  .table-responsive td {
    display: block;
    /* Stack the table rows and cells */
    width: 100%;
    /* Make each cell span the full width */
  }

}

.big-text-multi-screen {
  font-size: 1.75rem;
}

.entity-users-head{
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
  font-size: 18px;
  font-weight: 600;
}

.card-body{
  padding: 1.25rem 0.6rem;
}

@media screen and (max-width:990px) {
  .big-text-multi-screen {
    font-size: 20px !important
  }
}

.central-show-container {
  color: #000000;
  position: absolute;
  top: 50%;
  left: 50%;
  background: black;
  margin-left: -223px;
  margin-top: -56px;
  min-width: 445px;
  min-height: 50px;
  opacity: 0.65;
  border-left: 10px solid #cbf1ff;
  border-right: 10px solid #cbf1ff;
}

@media screen and (max-width:500px) {
  .central-show-container {
    max-width: 400px;
  }
}

.full-width {
  width: 100%;
}

.full-width-flex {
  flex-grow: 1;
}
.map-container {
  height: 100%;
  width: 100%;
}


@keyframes inAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}