.form-container {
    display: block;
    /* removed to avoid extra space coming in the form  */
    /* margin-bottom: 60px;   */
}

.register-form-container {
    width: 80%;
    margin: auto;
    padding: 2rem;
    background-color: white;
    border-radius: 2px;
}

.login-form-container {
    min-width: 40%;
    margin: auto;
    background-color: #eee;
    border-radius: 2px;
    border: 1px black solid;
}

.form-top-heading {
    color: #00b7fd;
    font-size: 32px;
    font-weight: 600;
    text-align: center;

}

.form-heading {
    font-size: 30px;
    font-weight: 500;

}

.sub-form-header {
    font-size: 28px !important;
}

.sub-form-header-center{
    font-size: 28px !important;
    text-align: center;
}


.form-error-container,
.col span {
    color: red;
}

label {
    margin-top: 0.6em;
    margin-bottom: 0.2em !important;
    font-weight: 600;
    font-size: 1.16 rem;
}

input {
    border-width: 1px;
    border-radius: 0px;
}


/* to remove the default bootstap focus */
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
select.form-control:focus,
[contenteditable].form-control:focus {
    box-shadow: inset 0 -1px 0 #ddd;
    border: none;
    border-bottom: 2px solid #369;
}

.horizontal-field-label-min-250 {
    min-width: 250px;
}

.individual-field-text-area {
    /* min-width: 250px !important; */
    margin: 0px 10px;
}

.individual-field {
    min-width: 250px !important;
    margin: 0px 10px;
}

.individual-field-margin{
    margin: 0px 10px;
}

.individual-field-small {
    min-width: 200px;
    margin: 0px 10px;
}

.individual-field-micro{
    min-width: 100px;
    margin: 0px 10px;
}

.individual-field-margin {
    margin: 0px 10px;
}

.max-300{
    max-width: 300px !important;
}

.width-300{
    width: 300px;
}

.max-400{
    max-width: 400px !important;
}

.max-500{
    max-width: 500px !important;
}

.max-1000{
    max-width: 1060px !important;
}

.max-900{
    max-width: 900px !important;
}

.max-100{
    max-width: 100px !important;
}

.max-250{
    max-width: 250px !important;
}

.max-240{
    max-width: 240px !important;
}

.min-250{
    min-width: 250px !important;
}

.min-240{
    min-width: 240px !important;
}

.max-200{
    max-width: 200px !important;
}

.min-300{
    min-width: 300px !important;
}

.min-200{
    min-width: 200px !important;
}

.min-520{
    min-width: 520px !important;
}

.field-control {
    padding: 0.15rem 0.75rem 0rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #263a4e !important;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}


.sub-form-container {
    padding: 0.375rem 0.75rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #263a4e !important;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.sub-form-container-no-border .individual-field label{
    margin-top: 0;
}



.field-block-control {
    display: block;
    /* padding: 0.375rem 0.75rem; */
    padding: 0.2rem 0.4rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #263a4e !important;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}





/* Style for the file input wrapper */
.file-input-wrapper {
    position: relative;
    display: inline-block;
    overflow: hidden;
  }
  
  /* Style for the actual file input */
  .file-input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    display: none;
  }
  
  /* Style for the label */
  .file-input-label {
    display: block;
    padding: 5px 10px;
    background-color: #fafafa;
    color: black;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    transition: background-color 0.3s;
    max-width: 200px;
  }
  
  .file-input-label:hover {
    /* background-color: #0056b3; */
  }
  

  .image-preview-form{
    max-width: 500px;
    min-width: 500px;
    max-height: 200px;
    min-height: 200px;
    border: 2px solid black;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .error-message{
    color: red;
    font-weight: 600;
  }

.non-functional-element {
    pointer-events: none;
    opacity: 0.5;
    /* Optionally reduce opacity to indicate non-interactivity */
    user-select: none;
    /* Prevent text selection in some browsers */
    cursor: not-allowed;
    /* Display "not allowed" cursor to indicate non-interactivity */
}


.table thead th{
    vertical-align: middle;
    text-align: center;
}

.left-align {
    text-align: left;
}

.left-align .table thead th {
    text-align: left;
}

.right-align{
    text-align: right !important;
}

.break {
    flex-basis: 100%;
    height: 0;
}

.center-it{
    width: 100%;
    margin: auto;
}

.sub-form-header-second{
    font-size: 22px;
}

.registration-border{
    /* border: 2px #00b7fd solid; */
    border: 2px grey solid;
}

.sub-form-header-registration{
    font-size: 14px;
    font-weight: 500;
    padding-left: 10px;
    color: white;
    /* background-color: #00b7fd; */
    background-color: grey;
}

.sub-form-header-violation{
    background-color: #00b7fd;
}

.violation-border{
    border: 2px #00b7fd solid;
}


.table-bordered {
    border: 1px solid black;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid black;
}

.table-bordered thead th,
.table-bordered thead td {
    border-bottom: 1px solid black;
}

.btn-bordered {
    border: 1px solid;
}

.btn-100{
    min-width: 100px;
}

.gap-40-10{
    column-gap: 40px;
    row-gap: 10px;
}
.gap-20-10{
    column-gap: 20px;
    row-gap: 10px;
}

.registration-margin{
    margin-left: 5%;
    margin-right: 5%;
}

.registration-margin-left{
    margin-left: 5%;
}

.zero-margin-top{
    margin-top: 0;
}


/* Custom CSS to remove borders between columns */
.border-my-register {
    border-collapse: collapse;
}

.border-my-register th,
.border-my-register td {
    border: none;
    /* Remove borders from table cells */
}

.border-my-register tr {
    /* border-bottom: 1px solid black; 
    border-top: 1px solid black;  */
    border: 1px solid black;
}

.border-my-register thead tr {
    border-top: 1px solid black;
    /* Add a top border to the table header row */
}

.w-10 {
    width: 10% !important;
}

.w-40{
    width: 40% !important;
}

.mt-30{
    margin-top: 30px;
}

.w-25 {
    width: 25% !important;
}

.w-20 {
    width: 20% !important;
}

.password-toggle-parent {
    position: relative;
}

.password-toggle {
    position: absolute;
    top: 50%;
    right: 10px; /* Adjust the value to position the icon as per your design */
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1; /* Ensure it's above the input field */
  }