.agency-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .filter-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 30px;
  }
  
  .filter-button {
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    background-color: #f0f0f0;
    color: #333;
    transition: all 0.3s ease;
  }
  
  .filter-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .filter-button.active {
    background-color: #333;
    color: white;
  }
  
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;
  }
  
  .card {
    flex: 1 1 calc(25% - 20px);
    min-width: 280px;
    background-color: white;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 16px;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
  }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  .card:hover::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(90deg, #FFC107, #FF9800);
  }
  
  .card-header-g2g {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
  }
  
  .category-badge {
    background-color: #2E7D32;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    transition: transform 0.3s ease;
  }
  
  .card:hover .category-badge {
    transform: scale(1.05);
  }
  
  .agency-name {
    color: #B87503;
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    transition: color 0.3s ease;
  }
  
  .card:hover .agency-name {
    color: #945e02;
  }
  
  .address-label {
    color: #666;
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 4px 0;
  }
  
  .address-text {
    color: #666;
    font-size: 14px;
    margin: 0;
    line-height: 1.4;
    font-weight: 500;
    font-size: 15px;
  }
  
  .button-container {
    display: flex;
    justify-content: flex-start;
    margin-top: auto;
  }
  
  .view-more-button {
    width: 120px;
    background-color: #FFC107;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    color: #333;
    font-size: 14px;
    font-weight: 700;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    text-align: center;
  }
  
  .view-more-button:hover {
    background-color: #FFB300;
    transform: translateX(3px);
  }
  
  .view-more-button::after {
    content: '→';
    position: absolute;
    right: -20px;
    opacity: 0;
    transition: all 0.3s ease;
  }
  
  .view-more-button:hover::after {
    right: 10px;
    opacity: 1;
  }
  
  /* Responsive Design */
  @media (max-width: 1200px) {
    .card {
      flex: 1 1 calc(33.333% - 20px);
    }
  }
  
  @media (max-width: 900px) {
    .card {
      flex: 1 1 calc(50% - 20px);
    }
  }
  
  @media (max-width: 600px) {
    .card {
      flex: 1 1 100%;
    }
    
    .filter-container {
      gap: 8px;
    }
    
    .filter-button {
      padding: 6px 12px;
      font-size: 13px;
    }
  }