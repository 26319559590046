.multi-select-container {
  position: relative;
}

.selected-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 5px; 
}

.tag {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 5px;
}

.close-icon {
  cursor: pointer;
  margin-left: 5px;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  display: none; /* Initially hidden */
  z-index: 1;
}

.dropdown ul {
  list-style: none;
  padding: 0;
}

.dropdown li {
  margin-bottom: 5px;
}