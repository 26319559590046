body {
  margin: 0;
  font-family: 'Rajdhani',  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Rajdhani' , source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace !important;
}
/* 
input[type="radio"] {
  margin-right: 5px;
  cursor: pointer;
} */


.register-body{
  font-family:  'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

.register-body label{
  font-size: 15px;
  font-weight: 500;
}

.radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  padding-right: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: .2rem !important;
  margin-bottom: .2rem !important;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #cbf1ff;
  opacity: 0.2;
  border-radius: 50%;
  border: 2px solid black;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
  /* background-color: #ccc; */
  opacity: 0.5;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
  background-color: black;
  opacity: 1;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
 	top: 4px;
	left: 4px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}


/* The checkbox container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  padding-right: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: .2rem !important;
  margin-bottom: .2rem !important;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox-checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 15px;
  width: 15px;
  /* background-color: #eee; */
  background-color: white;
  opacity: 0.7;
  border: 2px solid black;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkbox-checkmark {
  /* background-color: #ccc; */
  opacity: 0.9;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkbox-checkmark {
  background-color: black;
  opacity: 1;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkbox-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkbox-checkmark:after {
  left: 3px;
  top: -1px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}




input[type="text"]:active{
  border: 1px solid #cbf1ff !important;
}


.rectangle-box-small-heading{
  font-size: 22px;
  font-weight: 700;
  text-align: center;
}

.rectangle-box-small{
  font-size: 20px;
  font-weight: 600;
}

.normal-label{
  margin-top: 0.6em;
  margin-bottom: 0.2em !important;
  font-weight: 600;
  font-size: 1.16 rem;
}


.profile-tab{
  font-family:  'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

.profile-tab .nav-item{
  font-size: 14px;
}

.offline-top-message{
  background-color: red;
  display: block;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  color: white;
  font-size: 20px;
  width: 100%;
  font-weight: 700;
}
